import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import ProtectedRoute from './ProtectedRoute';
import NotFound from "../pages/NotFound";
import Loader from "../components/Loader";

const Home = lazy(() => import('../pages/Home'));
const Venues = lazy(() => import('../pages/Venues'));
const SkyCams = lazy(() => import('../pages/SkyCams'));
const Map = lazy(() => import('../pages/Map'));
const Blogs = lazy(() => import('../pages/blogs/Blogs'));
const BlogDetails = lazy(() => import('../pages/blogs/BlogDetails'));
const DownloadPage = lazy(() => import('../pages/DownloadPage'));
const Contact = lazy(() => import('../pages/Contact'));
const DrinkDine = lazy(() => import('../pages/DrinkDine'));
// const ProductList = lazy(() => import('../pages/shop/ProductList'));
// const ProductDetails = lazy(() => import('../pages/shop/ProductDetails'));
// const ProductCart = lazy(() => import('../pages/shop/ProductCart'));


const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const Routing = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <>
            <Suspense fallback={<Loader />}>
                <BrowserRouter>
                    <ScrollToTop />
                    <Routes>
                        <Route element={<ProtectedRoute />}>
                            <Route exact path="/" element={<Home />} />
                            <Route exact path="/venues" element={<Venues />} />
                            <Route exact path="/sky-cams" element={<SkyCams />} />
                            <Route exact path="/map" element={<Map />} />
                            <Route exact path="/blog" element={<Blogs />} />
                            <Route exact path="/blog-details/:id" element={<BlogDetails />} />
                            {/* <Route exact path="/product-list" element={<ProductList />} /> */}
                            {/* <Route exact path="/product-detail" element={<ProductDetails />} /> */}
                            {/* <Route exact path="/cart-list" element={<ProductCart />} /> */}
                            <Route exact path="/download" element={<DownloadPage />} />
                            <Route exact path="/contact" element={<Contact />} />
                            <Route exact path="/drink&dine" element={<DrinkDine />} />
                        </Route>
                        <Route exact path="*" element={<NotFound />} />

                    </Routes>
                </BrowserRouter>
            </Suspense>
        </>
    )
}

export default Routing;
