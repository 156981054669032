import ReactGA from 'react-ga';
import Routing from './routes/Routing';
import { ToastContainer } from 'react-toastify';

const App = () => {
  ReactGA.initialize('G-2QBV601PY7');
  return (
    <>
      <Routing />
      <ToastContainer />
    </>
  )
}
export default App;